import { useState, useEffect } from "react";
import * as React from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import CssBaseline from "@mui/material/CssBaseline";
import Container from "@mui/material/Container";
import { red } from "@mui/material/colors";
import { theme } from "../Components/theme";
import Grid from "@mui/material/Grid";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import Button from '@mui/material/Button';


function Resume() {
  const color = red[500];

  const [jobs, setJobs] = useState([]);
  useEffect(() => {
    setJobs([
      {
        company: "Kadence Collective",
        title: "Software QA Tester",
        date: "August 2021 - January 2023",
        description:
          "Participated in the design and development of projects using Salesforce products. Understood and analyzed user stories and business requirements to design, develop, and implement manual and automated test strategies and ensure high-quality software projects. Conducted thorough testing as the end user, designed and performed demonstrations for the client during weekly sprints, and ensured all project requirements were met. Accurately reported discovered bugs and defects while working with an Agile team to meet project deadlines.",
      },
      {
        company: "Appddiction Studio",
        title: "Software Developer",
        date: "2020 - 2021",
        description:
          "Worked with a team of developers on both government and commercial projects. Effectively coordinated with the Scrum Master, testers, and product owners throughout the Agile development process. Continuously sought out new technologies to meet the dynamic needs of our clients. Primarily focused on the migration and redesign of a private government application, successfully updating it from Java 6 to Java 11 and implementing Adobe XD and Angular 9.",
      },
      {
        company: "Randolph Brooks Federal Credit Union (RBFCU)",
        title: "Member Service Representative III / Supervisor on Duty",
        date: "2016 - 2018",
        description:
          "Excelled in several key areas, including supervision of employees, de-escalation, and legal document verification. Ability to assist Spanish-speaking members was an asset to the organization. Additionally,  was responsible for training new hires on product knowledge, selling procedures, and company software. Maintained responsibilities held under previous representative titles.",
      },
    ]);
  }, []);

  const [skills, setSkills] = useState([]);
  useEffect(() => {
    setSkills([
      "HTML",
      "CSS",
      "BootStrap",
      "Materialize",
      "Material UI",
      "jQuery",
      "JavaScript",
      "TypeScript",
      "Angular",
      "React",
      "API Implementation",
      "Java",
      "Spring Boot",
      "Object Oriented Programming",
      "MySQL",
      "Agile",
      "SCRUM",
      "Kanban",
      "Test Plans",
      "Test Cases",
      "Test Processes",
      "UI & Compatibility Testing",
      "Testing Automation",
      "Eclipse",
      "Selenium IDE",
      "Salesforce",
      "Marketing Cloud",
      "Experience Cloud",
    ]);
  }, []);
  return (
    <>
      <Container style={theme.bodyPosition}>
        <CssBaseline />
        <div>
          <Container maxWidth="md" style={theme.yellowFont}>
            <h1>Experience</h1>
          </Container>
          <div>
            {jobs.map((item, index) => (
              <div key={index}>

                <Container maxWidth="md" >
                  <Card
                    sx={{ minWidth: 275, mb: 4 }}
                    style={theme.cardStyle}
                    elevation="0"
                  >
                    <CardContent>
                      <Typography variant="h5" component="div">
                        {item.title}
                      </Typography>
                      <Typography sx={{ mb: 1.5 }} color="grey">
                        {item.company} {item.date}
                      </Typography>
                      <Typography variant="body2">
                        {item.description}
                      </Typography>
                    </CardContent>
                  </Card>
                </Container>

              </div>
            ))}
          </div>
        </div>
        <div>
          <Container maxWidth="md" style={theme.yellowFont}>
            <h1>Skills</h1>
          </Container>
          <div>
            <Container maxWidth="md" >
              <Card
                sx={{ minWidth: 275, mb: 4 }}
                style={theme.cardStyle}
                elevation="0"
              >
                <CardContent>
                  <Typography variant="subtitle1" component="div">
                    <Grid container spacing={6}>
                      <Grid item xs={6}>
                        <List>
                          {skills
                            .slice(0, skills.length / 2)
                            .map((item, index) => (
                              <ListItem key={index}>{item}</ListItem>
                            ))}
                        </List>
                      </Grid>
                      <Grid item xs={6}>
                        <List>
                          {skills
                            .slice(skills.length / 2, (skills.length / 2) * 2)
                            .map((item, index) => (
                              <ListItem key={index}>{item}</ListItem>
                            ))}
                        </List>
                      </Grid>
                      <Grid item xs={6}>
                        <List>
                          {skills
                            .slice((skills.length / 2) * 2)
                            .map((item, index) => (
                              <ListItem key={index}>{item}</ListItem>
                            ))}
                        </List>
                      </Grid>
                    </Grid>
                  </Typography>
                </CardContent>
              </Card>
            </Container>
          </div>

          <Container maxWidth="md" style={theme.yellowFont}>
            <h1>Education</h1>
          </Container>
          <div>
            <Container maxWidth="md" >
              <Card
                sx={{ minWidth: 275, mb: 4 }}
                style={theme.cardStyle}
                elevation="0"
              >
                <CardContent>
                  <Typography variant="h5" component="div">
                    Full-Stack Web Development Certificate
                  </Typography >
                  <Typography sx={{ mb: 1.5 }} color="grey">
                    Codeup, December 2019
                  </Typography>
                  <Typography variant="body2">
                    A fully-immersive, project-based, and intensive 20-week full-stack Java career accelerator that provided 600+ hours of expert instruction in software development.
                  </Typography>

                </CardContent>
                <CardContent>
                  <Typography variant="h5" component="div">

                    AWS Cloud Practitioner Certification
                  </Typography >
                  <Typography sx={{ mb: 1.5 }} color="grey">
                    In Progress
                  </Typography>

                </CardContent>
              </Card>
            </Container>
            <Container maxWidth="md" sx={{textAlign: 'center'}}>
            <div >   
              
                  <Button style={theme.button}>
                    <a style={theme.links} className="button" href="/resume/BeverlyJaimesPuente-Resume.pdf"
                      download>
                      Dowload Resume

                    </a>
                  </Button>
                  </div>
          </Container>
            <div>


            </div>
          </div>
        </div>

      </Container>
    </>
  );
}

export default Resume;
