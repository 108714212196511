import {Link} from 'react-router-dom';
import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import MenuIcon from '@mui/icons-material/Menu';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import {theme} from './theme';
import logo from '../Images/b.png';


const drawerWidth = 240;

function NavBar(props) {
 
 const { window } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen((prevState) => !prevState);
  };

  const drawer = (
    <Box onClick={handleDrawerToggle} sx={{ textAlign: 'center'}}>
      
      <Typography variant="h6" sx={{ my: 2}} >
      
      <Link to='/' style={theme.links}>
                <Button style={theme.sideNav}>
                <img src={logo} style={theme.logo}></img>
                </Button>
      </Link>
      </Typography>
      <Divider />
      <List>
        
          <ListItem disablePadding>
            <ListItemButton sx={{ textAlign: 'center' }}>
                <Link to='/' style={theme.links}>
                <Button style={theme.sideNav}>Home</Button>
                </Link>
            </ListItemButton>
          </ListItem>
          <ListItem disablePadding>
            <ListItemButton sx={{ textAlign: 'center' }}>
                <Link to='/Resume' style={theme.links}>
                <Button style={theme.sideNav}>Resume</Button>
                </Link>
            </ListItemButton>
          </ListItem>
          <ListItem disablePadding>
            <ListItemButton sx={{ textAlign: 'center' }}>
                <Link to='/Contact' style={theme.links}>
                <Button style={theme.sideNav}>Contact</Button>
                </Link>
            </ListItemButton>
          </ListItem>
          {/* <ListItem disablePadding>
            <ListItemButton sx={{ textAlign: 'center' }}>
                <Link to='/Weather' style={theme.links}>
                <Button style={theme.sideNav}>Weather</Button>    
                </Link>
            </ListItemButton>
          </ListItem> */}
    
      </List>
    </Box>
  );

  const container = window !== undefined ? () => window().document.body : undefined ;

  return (
    
    <Box sx={{ display: 'flex' }} >
      <CssBaseline />
      <AppBar component="nav" style={theme.nav}>
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { sm: 'none' } }}
          >
            <MenuIcon />
          </IconButton>
          <Typography
            variant="h6"
            component="div"
            sx={{ flexGrow: 1, display: { xs: 'none', sm: 'block' } }}
          >
           

           <Link to='/' style={theme.links}>
              <Button sx={{ color: '#fff' }}>
            <img src={logo} style={theme.logo}></img>
                
              </Button>
              </Link>
            
         
          </Typography>
         
          <Box sx={{ display: { xs: 'none', sm: 'block' } }} >
          
              <Link to='/' style={theme.links}>
              <Button sx={{ color: '#fff' }}>
                Home
              </Button>
              </Link>
            
              <Link to='/Resume' style={theme.links}>
              <Button sx={{ color: '#fff' }}>
                Resume
              </Button>
              </Link>
        
              <Link to='/Contact' style={theme.links}>
              <Button sx={{ color: '#fff' }}>
                Contact
              </Button>
              </Link>
            
              {/* <Link to='/Weather' style={theme.links}>
              <Button sx={{ color: '#fff' }}>
                Weather
              </Button>
              </Link> */}
        
          </Box>
        </Toolbar>
      </AppBar>
      <Box component="nav">
        <Drawer  PaperProps={{
          sx: {
            backgroundColor: "#212121",
            color: 'white'
          }
        }}
  
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: 'block', sm: 'none' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
          }}

          
        >
          {drawer}
        </Drawer>
      </Box>
      
    </Box>
  );
}





export default NavBar;
