import * as React from "react";
import Container from "@mui/material/Container";
import { theme } from "../Components/theme";
import PhoneIphoneIcon from '@mui/icons-material/PhoneIphone';
import EmailIcon from '@mui/icons-material/Email';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import GitHubIcon from '@mui/icons-material/GitHub';
import Grid from "@mui/material/Grid";
import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';
import IconButton from '@mui/material/IconButton';
import { useState, useEffect } from 'react';




const Item = styled(Paper)(({ theme }) => ({

  padding: theme.spacing(1),
  textAlign: 'center',

}));


function Contact() {
  const [width, setWidth] = useState(window.innerWidth);



  useEffect(() => {
    const handleResize = () => setWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  let BevStyle = {};
  if (width < 600) {
    BevStyle = { fontSize: "12px", fontWeight: 'bold' };
  } else {
    BevStyle = { fontSize: "20px", fontWeight: 'bold' };
  }




  const myEmail = 'beverly.jaimespuente@gmail.com';


  return (
    <>
      <Container style={theme.bodyPosition}>
        <div>
          <Container maxWidth="md" style={theme.yellowFont}>
            <h1>Contact Me</h1>
          </Container>
          <div>
            <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }} sx={{ mt: 6 }} >
              <Grid item xs={12} md={6} sx={{ mt: 2 }}>
                <Item style={theme.cardStyle} elevation="0">
                  <PhoneIphoneIcon sx={{ fontSize: 60 }} style={theme.yellowFont} />
                  <h3 style={BevStyle}>(737) 224 - 3755</h3>
                </Item>
              </Grid>
              <Grid item xs={12} md={6} sx={{ mt: 2 }}>
                <Item style={theme.cardStyle} elevation="0">
                  <a href={`mailto:${myEmail}`} >

                    <EmailIcon sx={{ fontSize: 60 }} style={theme.yellowFont} />

                  </a>
                  <h3 style={BevStyle}>beverly.jaimespuente@gmail.com</h3>
                </Item>
              </Grid>
              <Grid item xs={12} md={6} sx={{ mt: 2 }}>
                <Item style={theme.cardStyle} elevation="0">
                  <IconButton style={theme.yellowFont} variant="outlined" href="https://linkedin.com/in/beverly-jaimes-puente">
                    <LinkedInIcon sx={{ fontSize: 60 }} />
                  </IconButton>

                </Item>
              </Grid>
              <Grid item xs={12} md={6} sx={{ mt: 2 }}>
                <Item style={theme.cardStyle} elevation="0">
                  <IconButton style={theme.yellowFont} variant="outlined" href="https://github.com/beverlyjaimes">
                    <GitHubIcon sx={{ fontSize: 60 }} />
                  </IconButton>
                </Item>
              </Grid>
            </Grid>

          </div>
        </div>
      </Container>

    </>
  );
}

export default Contact;
