
const theme = {
  
    links: {
        textDecoration: 'none',
        focus: 'none',
        visited: 'none',
        active: 'none',
        hover: 'none',
    },
    nav: {
        backgroundColor: 'black',
        
    },
    sideNav: {
        color: 'white'
    },
    bodyPosition: {
        marginTop: '100px',
        paddingBottom: '2rem'
    //     position: 'relative',
    //     top:'100px',
    //    marginBottom: '200px'
    },
   yellowFont: {
    color: '#ffc400'
   } ,
   cardStyle: {
    backgroundColor: '#212121',
    color: 'white'
   },
   logo: {
    maxWidth: 40,
    marginRight: '10px'
  },
  button: {
    backgroundColor: "#ffc400",
   
  }
}

export {theme};