
import {useState, useEffect} from 'react';
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import {theme} from '../Components/theme';
import Container from "@mui/material/Container";





function Home() {
  const [width, setWidth] = useState(window.innerWidth);



  useEffect(() => {
    const handleResize = () => setWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  let BevStyle = {};
  if (width < 600) {
    BevStyle = {fontSize: "100px", fontWeight: 'bold'};
  } else {
    BevStyle = {fontSize: "200px", fontWeight: 'bold' };
  }
  const card = (
    <>
        <CardContent>
          <Typography sx={{ fontSize: 30,}} color="lightgrey">
            HI IM 
          </Typography>
          <Typography variant="h5" component="div"></Typography>
          <Typography sx={{ mb: 1.5, textAlign: 'center'}}
          style={ BevStyle} color='#ffc400'
          >
            BEV
          </Typography>
          <Typography variant="body2" sx={{ fontSize: 30 }}  style={{textAlign: "right"}} color="lightgrey">
           A SOFTWARE 
           <br></br>
           DEVELOPER
     
          </Typography>
        </CardContent>
    </>
    );
  return (
    <>
    <Container sx={{ minWidth: 275, mt:6}} style={theme.bodyPosition } maxWidth="sm">

        <Card style={{backgroundColor: '#212121'}} elevation={0}>{card}</Card>
     
    </Container>
    </>
  );
}

export default Home;
