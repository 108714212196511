import "./App.css";
import Resume from "./Pages/Resume";
import Contact from "./Pages/Contact";
import { Routes, Route } from "react-router-dom";
import Home from "./Pages/Home";
import NavBar from "./Components/NavBar";
import React from "react";
import Weather from "./Pages/Weather";

function App() {
  return (
    <div style={{ backgroundColor: "#212121", minHeight: '100vh' }}>

      <NavBar />
      <Routes>

        <Route path="/Resume" element={<Resume  />} />        
        <Route path="/Contact" element={<Contact />} />
        <Route path="/Weather" element={<Weather />} />

        <Route index element={<Home />} />
      </Routes>
    </div>
  );
}

export default App;
